import React from 'react'
import { graphql } from 'gatsby'

import Layout from '../components/layout'
import LandingHero from '../components/single-landing/hero'
import LandingMain from '../components/single-landing/main-content'
import GetCracking from '../components/get-cracking'
import SEO from '../components/single-landing/seo'

const SingleLanding = ({ data, pageContext }) => (
  <Layout>
    <SEO pageTitle={pageContext.title} slug={pageContext.slug} {...data.wordPress.landingBy.seo} />
    <LandingHero
      hero={data.wordPress.landingBy.landingpageFields.heroInfos}
      title={data.wordPress.landingBy.title}
    />
    <LandingMain mainContent={data.wordPress.landingBy.landingpageFields.mainContent} />
    <GetCracking />
  </Layout>
)

export default SingleLanding

export const query = graphql`
  query($wordpressId: Int!) {
    wordPress {
      landingBy(landingId: $wordpressId) {
        title
        ...SeoLandingFragment
        landingpageFields {
          ...LandingHeroFragment
          ...LandingMainContentFragment
        }
      }
    }
  }
`
