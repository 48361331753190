import React, { useState, useRef } from 'react'
import styled from 'styled-components'
import { useSpring, animated } from 'react-spring'
import Observer from '../observer'

const LandingQuote = ({ block }) => {
  const [animatedQuote, setAnimatedQuote] = useState(false)
  const [animatedQuoteCredit, setAnimatedQuoteCredit] = useState(false)
  const quoteRef = useRef()
  const creditRef = useRef()

  const quoteAnimation = useSpring({
    transform: animatedQuote ? `translate3d(0px,0px,0px)` : `translate3d(0,50px,0)`,
    opacity: animatedQuote ? 1 : 0,
    config: { mass: 5, tension: 500, friction: 100 }
  })

  const quoteCreditAnimation = useSpring({
    transform: animatedQuoteCredit ? `translate3d(0px,0px,0px)` : `translate3d(0,50px,0)`,
    opacity: animatedQuoteCredit ? 1 : 0,
    config: { mass: 5, tension: 500, friction: 100 }
  })

  return (
    <Outer>
      <Observer element={quoteRef.current} toggle={animatedQuote} setToggle={setAnimatedQuote}>
        <Quote style={quoteAnimation} ref={quoteRef}>
          <p>{block.title}</p>
        </Quote>
      </Observer>

      <Observer
        element={creditRef.current}
        toggle={animatedQuoteCredit}
        setToggle={setAnimatedQuoteCredit}
      >
        <QuoteCredits style={quoteCreditAnimation} ref={creditRef}>
          <p>{block.name}</p>
          <p>{block.position}</p>
        </QuoteCredits>
      </Observer>

      <hr />
    </Outer>
  )
}

const Outer = styled.div`
  position: relative;
  width: 100%;
  padding: 13rem 15% 0 15%;

  @media (max-width: 650px) {
    padding: 65px 30px 65px 30px;
  }

  hr {
    margin: 0 2.5rem;

    @media (max-width: 769px) {
      margin: 0;
    }
  }
`

const Quote = styled(animated.div)`
  width: 85%;
  margin: 0 auto;
  text-align: center;

  @media (max-width: 769px) {
    width: 100%;
  }

  p {
    color: ${props => props.theme.colours.grey};
    font-family: ${props => props.theme.fonts.sofia};
    font-size: 2.5rem;
    margin: 0 2.5rem;

    @media (max-width: 769px) {
      margin: 0;
    }
  }
`

const QuoteCredits = styled(animated.div)`
  text-align: center;
  margin: 5rem 0 15rem 0;

  @media (max-width: 769px) {
    margin: 2.5rem 0 7.5rem 0;
  }

  p {
    font-family: ${props => props.theme.fonts.sofia};
    font-size: 2rem;
    margin: 0;
  }
`

export default LandingQuote
