import React, { useState, useRef } from 'react'
import Img from 'gatsby-image/withIEPolyfill'
import styled from 'styled-components'

import { useTrail, animated } from 'react-spring'
import Observer from '../observer'

const LandingBreakdown = ({ block }) => {
  const [animationToggle, setAnimationToggle] = useState(false)
  const ref = useRef()

  const breakdownItemAnimation = useTrail(block.item.length, {
    opacity: animationToggle ? 1 : 0,
    x: animationToggle ? 0 : 20,
    from: { opacity: 0, x: 20 },
    config: {
      mass: 1,
      tension: 2500,
      friction: 175
    },
    delay: animationToggle ? 300 : 50
  })

  return (
    <Outer>
      <Observer element={ref.current} toggle={animationToggle} setToggle={setAnimationToggle}>
        <WrapResultsContent ref={ref}>
          <Title>{block.title}</Title>
          <WrapResults>
            {breakdownItemAnimation.map(({ x, ...rest }, index) => {
              const item = block.item[index]
              return (
                <ResultItem
                  key={index}
                  style={{
                    ...rest,
                    // eslint-disable-next-line no-shadow
                    transform: x.interpolate(x => `translate3d(0,${x}px,0)`)
                  }}
                >
                  <WrapImage>
                    <img src={item.iconPink.sourceUrl} alt={item.iconPink.alttext} />
                  </WrapImage>
                  <ResultText>{item.title}</ResultText>
                </ResultItem>
              )
            })}
          </WrapResults>

          <TextResults>{block.text}</TextResults>
        </WrapResultsContent>
      </Observer>
    </Outer>
  )
}

const Outer = styled.div`
  position: relative;
  width: 100%;
`

const Title = styled.h2`
  color: ${props => props.theme.colours.pink};
  font-family: ${props => props.theme.fonts.sofia};
  font-size: 5.5rem;
  letter-spacing: -0.075rem;
  margin-left: 2.5rem;
  text-align: center;

  @media (max-width: 650px) {
    margin-left: 3rem;
  }
`

const WrapResultsContent = styled.div`
  position: relative;
  max-width: 100%;
  padding: 12.5rem 15% 8rem 15%;
  z-index: 5;

  @media (max-width: 769px) {
    max-width: 100%;
    padding: 5rem 3.5rem 5.5rem 3.5rem;
  }
`

const WrapResults = styled.div`
  display: flex;
  justify-content: stretch;
  align-items: flex-start;
  margin-left: 2.5rem;
  margin-right: 2.5rem;
  margin: 60px 0 0 0;

  @media (max-width: 1024px) {
    flex-wrap: wrap;
    justify-content: center;
    margin: 50px 0 0 0;
  }

  @media (max-width: 650px) {
    justify-content: space-between;
    margin: 40px 0 0 0;
  }
`

const ResultItem = styled(animated.div)`
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;

  @media (max-width: 1024px) {
    margin-bottom: 5rem;
    width: 45%;
  }
`

const WrapImage = styled.div`
  position: relative;
  width: 132px;
  height: 132px;
  margin: 0 auto 2rem auto;
  display: flex;
  justify-content: center;
  align-items: flex-end;
  border: 1px solid ${props => props.theme.colours.lightGrey};
  border-radius: 50%;

  @media (max-width: 1024px) {
    width: 100px;
    height: 100px;
  }

  @media (max-width: 650px) {
    margin: 5rem auto 2rem auto;
    width: 80px;
    height: 80px;
  }

  img {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 50px;
    height: 50px;

    @media (max-width: 1024px) {
      width: 40px;
      height: 40px;
    }

    @media (max-width: 650px) {
      width: 35px;
      height: 35px;
    }
  }
`

const ResultText = styled.p`
  width: 80%;
  color: ${props => props.theme.colours.grey};
  font-family: ${props => props.theme.fonts.sofia};
  font-weight: 700;
  font-size: 2rem;
  line-height: 1.45;
  margin: 0 auto;
  text-align: center;

  @media (max-width: 1150px) {
    font-size: 1.75rem;
  }

  @media (max-width: 650px) {
    font-size: 16px;
  }
`

const TextResults = styled.p`
  width: 80%;
  margin: 0 auto;
  color: ${props => props.theme.colours.grey};
  font-family: ${props => props.theme.fonts.circular};
  font-weight: 300;
  font-size: 1.6rem;
  line-height: 1.75;

  @media (max-width: 650px) {
    width: 100%;
    margin: 5rem 0 0 0;
    font-size: 2.25rem;
    line-height: 1.85;
  }
`

export default LandingBreakdown
