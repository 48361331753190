import React, { useContext, useState, useEffect, useRef } from 'react'
import { graphql } from 'gatsby'
import styled, { keyframes } from 'styled-components'
import { useSpring, animated } from 'react-spring'
import { ButtonContext } from '../context/button-context'
import InView from '../in-view'

export const fragment = graphql`
  fragment LandingHeroFragment on WordPress_Landing_Landingpagefields {
    heroInfos {
      titleImage {
        altText
        sourceUrl
        mediaItemId
        modified
        imageFile {
          publicURL
          childImageSharp {
            fluid(maxWidth: 1200, quality: 80) {
              ...GatsbyImageSharpFluid_withWebp_noBase64
            }
          }
        }
      }
      text
      buttonText
      buttonLink {
        url
      }
    }
  }
`

const LandingHero = ({ hero, title }) => {
  // eslint-disable-next-line no-unused-vars
  const triggerRef = useRef()
  const [isDesktop, setIsDesktop] = useState(null)
  // eslint-disable-next-line no-unused-vars
  const [buttonTriggers, setButtonTriggers] = useContext(ButtonContext)

  useEffect(() => {
    if (typeof window !== `undefined`) {
      setIsDesktop(window.innerWidth > 1024)
    }
  }, [])

  const smallTextAnimation = useSpring({
    from: { opacity: 0, transform: `translate3d(0px,25px,0px)` },
    to: { opacity: 1, transform: `translate3d(0px,0px,0px)` },
    delay: 400,
    config: { mass: 5, tension: 500, friction: 100 }
  })

  const svgTextAnimation = useSpring({
    from: { opacity: 0, transform: `translate3d(0px,50px,0px)` },
    to: { opacity: 1, transform: `translate3d(0px,0px,0px)` },
    delay: 500,
    config: { mass: 5, tension: 500, friction: 100 }
  })

  const textAnimation = useSpring({
    from: { opacity: 0, transform: `translate3d(0px,50px,0px)` },
    to: { opacity: 1, transform: `translate3d(0px,0px,0px)` },
    delay: 700,
    config: { mass: 5, tension: 500, friction: 100 }
  })

  const buttonAnimation = useSpring({
    from: { opacity: 0, transform: `translate3d(0px,50px,0px)` },
    to: { opacity: 1, transform: `translate3d(0px,0px,0px)` },
    delay: 900,
    config: { mass: 5, tension: 500, friction: 100 }
  })

  return (
    <Outer>
      {!isDesktop && (
        <InView element={triggerRef.current} setToggle={setButtonTriggers} location="hero">
          <TriggerButton ref={triggerRef} />
        </InView>
      )}
      <WrapLandingHero>
        <WrapImage>
          <animated.p style={smallTextAnimation}>Services</animated.p>
          <animated.div style={svgTextAnimation}>
            <h1>{title}</h1>
            <HeroButton style={buttonAnimation} href={hero.buttonLink.url}>
              {hero.buttonText}
            </HeroButton>
          </animated.div>
        </WrapImage>
        <WrapInfos>
          <MainContent style={textAnimation}>{hero.text}</MainContent>
        </WrapInfos>
      </WrapLandingHero>
    </Outer>
  )
}

const Outer = styled.div`
  position: relative;
  margin: 0 auto;
  padding: 15% 10% 5% 10%;
  overflow: hidden;
  z-index: 20;

  @media (min-width: 1920px) {
    padding: 15% 12.5% 10% 12.5%;
  }

  @media (max-width: 1260px) {
    padding: 17rem 10%;
  }

  @media (max-width: 1024px) {
    padding: 20rem 10% 10% 10%;
  }

  @media (max-width: 650px) {
    padding: 15rem 0 0 0;
  }

  @media (max-width: 650px) and (min-height: 750px) {
    padding: 15rem 0 0 0;
  }
`

const TriggerButton = styled.div`
  position: absolute;
  top: 20%;
  width: 100%;
  height: 1px;
  background-color: transparent;
`

const WrapLandingHero = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;
`

const shine = keyframes`
  to {
    background-position: 100% center;
  }
`

const WrapImage = styled.div`
  width: 35%;
  margin: -2.5% 2.5% 0 7.5%;

  @media (max-width: 1024px) {
    width: calc(80% - 6rem);
    margin: 0 3rem 10% 3rem;
  }

  h1 {
    display: inline-block;
    font-family: ${props => props.theme.fonts.sofia};
    font-size: 6.5rem;
    font-weight: 700;
    background: linear-gradient(-45deg, #f74ea1 0%, #32c9bd 36%, #9692eb 69%, #f74ea1 90%);
    background-size: 300% auto;
    color: ${props => props.theme.colours.pink};
    word-wrap: normal;
    background-clip: text;
    text-fill-color: transparent;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    animation: ${shine} 10s linear infinite alternate;

    @media (max-width: 1024px) {
      display: inline-block;
    }

    @media (max-width: 650px) {
      font-size: 6.5rem;
    }
  }

  p {
    color: ${props => props.theme.colours.pink};
    font-family: ${props => props.theme.fonts.circular};
    font-size: 1.6rem;
    font-weight: 300;

    @media (max-width: 1024px) {
      font-size: 2.2rem;
    }

    @media (max-width: 650px) {
      font-size: 2.6rem;
    }
  }
`

const WrapInfos = styled.div`
  width: 40%;
  margin: 0 7.5%;

  @media (max-width: 1024px) {
    width: calc(100% - 6rem);
    margin: 0 3rem 10% 3rem;
  }

  & img {
    width: 100%;

    @media (max-width: 650px) {
      width: 100%;
    }
  }
`

const MainContent = styled(animated.p)`
  color: ${props => props.theme.colours.grey};
  font-family: ${props => props.theme.fonts.circular};
  font-weight: 300;
  font-size: 1.6rem;
  line-height: 1.75;
  margin-left: 2.5rem;

  @media (max-width: 1024px) {
    margin-left: 0;
    font-size: 2rem;
    line-height: 1.9;
  }

  @media (max-width: 650px) {
    margin-left: 0;
    font-size: 2.25rem;
    line-height: 1.9;
  }
`

const HeroButton = styled(animated.a)`
  background: transparent;
  border: 2px solid ${props => props.theme.colours.pink};
  padding: 20px 50px;
  color: ${props => props.theme.colours.pink};
  font-family: ${props => props.theme.fonts.circular};
  font-size: 1.2rem;
  font-weight: 700;
  text-transform: uppercase;
  text-decoration: none;
  border-radius: 30px;
  margin-top: 3.5rem;
  display: inline-block;
  margin-left: 0;
  transition: background-color 0.25s ease, color 0.25s ease;

  @media (max-width: 1024px) {
    display: inline-block;
    margin-top: 3rem;
    margin-left: 0;
    font-size: 1.5rem;
    border-radius: 5rem;
    padding: 2.5rem 6rem;
  }

  @media (max-width: 650px) {
    margin-left: 0;
    font-size: 1.7rem;
    border-radius: 5rem;
    padding: 2.5rem 6rem;
  }

  &:hover {
    background-color: ${props => props.theme.colours.pink};
    color: ${props => props.theme.colours.white};
  }
`

export default LandingHero
