import React from 'react'
import { graphql, Link } from 'gatsby'
import Img from 'gatsby-image/withIEPolyfill'
import styled from 'styled-components'

import LandingBreakdown from './breakdown'
import LandingQuote from './quote'

export const fragment = graphql`
  fragment LandingMainContentFragment on WordPress_Landing_Landingpagefields {
    mainContent {
      ... on WordPress_Landing_Landingpagefields_MainContent_ProjectOverview {
        fieldGroupName
        sectionTheme
        title
        text
        buttonText
        typeOfLink
        buttonUrl
        buttonLink {
          ... on WordPress_Project {
            slug
            link
          }
        }
        image {
          sourceUrl
          imageFile {
            childImageSharp {
              fluid(maxWidth: 1200, quality: 80) {
                ...GatsbyImageSharpFluid_withWebp_noBase64
              }
            }
          }
        }
      }
      ... on WordPress_Landing_Landingpagefields_MainContent_BreakdownInfos {
        fieldGroupName
        title
        item {
          title
          icon {
            sourceUrl
          }
          iconPink {
            sourceUrl
          }
        }
        background {
          sourceUrl
          imageFile {
            childImageSharp {
              fluid(maxWidth: 2000, quality: 80) {
                ...GatsbyImageSharpFluid_withWebp_noBase64
              }
            }
          }
        }
      }
      ... on WordPress_Landing_Landingpagefields_MainContent_QuoteInfos {
        fieldGroupName
        name
        position
        title
      }
      ... on WordPress_Landing_Landingpagefields_MainContent_Spacer {
        fieldGroupName
        size
      }
    }
  }
`

const SwitchLandingContent = (fieldName, block, i) => {
  /* eslint-disable */
  switch(fieldName) {
    case 'Landing_Landingpagefields_MainContent_ProjectOverview':
      return (
        <WrapContent key={i}>
          <WrapInfos>
            <SmallTitle>{block.sectionTheme}</SmallTitle>
            <Title>{block.title}</Title>
            <MainContent>{block.text}</MainContent>
            {block.typeOfLink === 'internal' && <Button to={`/work/${block.buttonLink.slug}`}>{block.buttonText}</Button> }
            {block.typeOfLink === 'url' && <a href={block.buttonUrl} target="_blank" rel="noopener">{block.buttonText}</a> }
          </WrapInfos>
          <WrapImage>
            <Image fluid={block.image.imageFile.childImageSharp.fluid} />
          </WrapImage>
        </WrapContent>
      )
      break;
    case 'Landing_Landingpagefields_MainContent_BreakdownInfos':
      return (
        <LandingBreakdown key={i} block={block} />
      )
      break;
    case 'Landing_Landingpagefields_MainContent_QuoteInfos':
      return (
        <LandingQuote key={i} block={block} />
      )
      break;
    case 'Landing_Landingpagefields_MainContent_Spacer':
      return (
        <Spacer key={i} block={block} style={{'marginTop': `${block.size}px` }} />
      )
      break;
    default:
      return null;
  }
  /* eslint-enable */
}

const LandingMain = ({ mainContent }) => (
  <Outer>
    {mainContent.map((block, i) => SwitchLandingContent(block.fieldGroupName, block, i))}
  </Outer>
)

const Outer = styled.section`
  position: relative;
  margin: 0 auto;
  display: flex;
  flex-wrap: wrap;
`

const Image = styled(Img)`
  @media (max-width: 1024px) {
  }
`

const WrapImage = styled.div`
  width: calc(65% - 2rem);

  @media (max-width: 1024px) {
    width: 100%;
  }
`

const WrapContent = styled.div`
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  padding: 0 12.5%;
  margin: 5% 0 5% 0;

  @media (max-width: 1024px) {
    flex-direction: column-reverse;
  }

  @media (max-width: 650px) {
    width: 100%;
    padding: 5% 3.5rem 45px;
  }

  &:nth-child(even) {
    flex-direction: row-reverse;

    @media (max-width: 1024px) {
      flex-direction: column-reverse;
    }

    & ${WrapImage} {
      margin: 0;
    }
  }

  &:last-child {
    margin: 5% 0 10% 0;
  }
`

const WrapInfos = styled.div`
  width: calc(35% - 5rem);
  margin-right: 2rem;
  margin-left: 5rem;

  @media (max-width: 1024px) {
    width: 100%;
    margin-left: 0;
    margin-right: 0;
  }

  a {
    background: transparent;
    border: 0.2rem solid ${props => props.theme.colours.pink};
    padding: 1.8rem 3.25rem;
    color: ${props => props.theme.colours.pink};
    font-family: ${props => props.theme.fonts.circular};
    font-size: 1.2rem;
    font-weight: 700;
    text-transform: uppercase;
    text-decoration: none;
    letter-spacing: 0.1rem;
    border-radius: 3rem;
    margin-top: 2.5rem;
    display: inline-block;
    transition: background-color 0.25s ease, color 0.25s ease;

    @media (max-width: 1024px) {
      margin-top: 3rem;
      margin-left: 0;
      font-size: 1.5rem;
      border-radius: 5rem;
      padding: 2.5rem 6rem;
    }

    @media (max-width: 650px) {
      font-size: 1.7rem;
      border-radius: 5rem;
      padding: 2.5rem 6rem;
    }

    &:hover {
      background-color: ${props => props.theme.colours.pink};
      color: ${props => props.theme.colours.white};
    }
  }
`

const SmallTitle = styled.p`
  color: ${props => props.theme.colours.pink};
  font-family: ${props => props.theme.fonts.circular};
  font-weight: 700;
  font-size: 1.2rem;
  letter-spacing: 0.22rem;
  text-transform: uppercase;

  @media (max-width: 650px) {
    margin-top: 2rem;
    font-size: 1.7rem;
  }
`

const Title = styled.h2`
  color: ${props => props.theme.colours.grey};
  font-family: ${props => props.theme.fonts.sofia};
  font-size: 4.5rem;
  letter-spacing: -0.075rem;
  margin-bottom: 1.5rem;

  @media (max-width: 650px) {
    font-size: 4.5rem;
  }
`

const MainContent = styled.p`
  color: ${props => props.theme.colours.grey};
  font-family: ${props => props.theme.fonts.circular};
  font-weight: 300;
  font-size: 1.6rem;
  line-height: 1.9;

  @media (max-width: 1024px) {
    font-size: 2rem;
    line-height: 1.8;
  }

  @media (max-width: 650px) {
    font-size: 2.25rem;
    line-height: 1.8;
  }
`

const Button = styled(Link)`
  background: transparent;
  border: 0.2rem solid ${props => props.theme.colours.pink};
  padding: 1.8rem 3.25rem;
  color: ${props => props.theme.colours.pink};
  font-family: ${props => props.theme.fonts.circular};
  font-size: 1.2rem;
  font-weight: 700;
  text-transform: uppercase;
  text-decoration: none;
  letter-spacing: 0.1rem;
  border-radius: 3rem;
  margin-top: 2.5rem;
  display: inline-block;
  transition: background-color 0.25s ease, color 0.25s ease;

  @media (max-width: 1024px) {
    margin-top: 3rem;
    margin-left: 0;
    font-size: 1.5rem;
    border-radius: 5rem;
    padding: 2.5rem 6rem;
  }

  @media (max-width: 650px) {
    font-size: 1.7rem;
    border-radius: 5rem;
    padding: 2.5rem 6rem;
  }

  &:hover {
    background-color: ${props => props.theme.colours.pink};
    color: ${props => props.theme.colours.white};
  }
`

const Spacer = styled.div`
  @media (max-width: 650px) {
    margin-top: 30px !important;
  }
`

export default LandingMain
